<template>
  <div>
    <b-table
      ref="school-leave-details-page-dt"
      id="school-leave-details-page-dt"
      responsive="sm"
      :fields="fields"
      :items="items"
      table-class="table-head-custom table-vertical-center mt-5"
      show-empty
      :busy="isBusy"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(paid_leave)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.is_paid_leave ? "Yes" : "No" }}
          </span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showLeaveDetails(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteSchoolLeave(data.item)"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty="">
        <AppEmptyList
          text-top="No Leaves added"
          text-bottom="You have no created Leave. To add new leave for your company, please click on the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        size="lg"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        @change="changePage"
      ></b-pagination>
    </div>

    <SchoolLeaveDetailModal
      title="Manage Leave"
      :selected-leave="selectedLeave"
      @detail-updated="fetch"
    ></SchoolLeaveDetailModal>
  </div>
</template>

<script>
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import { mapGetters } from "vuex";
import SchoolLeaveDetailModal from "@/modules/school/components/management/leave/detail/SchoolLeaveDetailModal";
export default {
  name: "SchoolLeaveDetailPageTable",
  components: { SchoolLeaveDetailModal, AppEmptyList, AppLoader },
  props: ["reload"],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: this.$t("EDULEAVE.TYPE"), sortable: true },
        { key: "description", label: this.$t("DETAILSTABLE.DESC") },
        { key: "paid_leave", label: this.$t("DETAILSTABLE.PAID") },
        { key: "actions", label: this.$t("DETAILSTABLE.ACT") },
      ],
      isBusy: false,
      selectedLeave: {},
    };
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.isBusy = true;
      return this.$store
        .dispatch("fetchSchoolLeaveDetailList")
        .then((response) => {
          console.log("Received", response);
          // this.items = response.data.data;
          return response.data.data;
        })
        .finally(() => (this.isBusy = false));
    },

    refresh() {
      console.log("Reload Table!");
      this.fetch();
    },

    changePage(page) {
      console.log("Change page to ", page);
      this.$store.commit("setSchoolLeaveDetailListPage", page);
      this.fetch();
    },

    deleteSchoolLeave(row) {
      console.log("Selected: ", row.name);

      this.$bvModal
        .msgBoxConfirm(`This will delete ${row.name}`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("deleteSchoolLeaveDetail", row.id)
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues bro");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    showLeaveDetails(row) {
      console.log("Selected leave ", row);

      this.selectedLeave = row.item;

      this.$bvModal.show("common-employee-details-modal");
    },
  },

  watch: {
    reload() {
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      items: "getSchoolLeaveDetailList",
      pagination: "getSchoolLeaveDetailListPagination",
      currentPage: "getSchoolLeaveDetailListPage",
    }),
  },
};
</script>

<style scoped></style>
