<template>
  <AppTableControl
    no-filter
    no-export
    @query-debounced="searchQuery"
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
export default {
  name: "SchoolLeaveDetailTableControl",
  components: { AppTableControl },
  beforeDestroy() {
    this.$store.dispatch("changeSchoolLeaveDetailListBaseFilter", {
      query: null,
    });
  },
  methods: {
    /**
     * Search query
     *
     * @param query
     */
    searchQuery(query) {
      console.log(query);

      this.$store.dispatch("changeSchoolLeaveDetailListBaseFilter", { query });

      this.$store.dispatch("fetchSchoolLeaveDetailList");
    },
  },
};
</script>

<style scoped></style>
