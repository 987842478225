import { render, staticRenderFns } from "./SchoolLeaveDetailPage.vue?vue&type=template&id=39e67505&scoped=true&"
import script from "./SchoolLeaveDetailPage.vue?vue&type=script&lang=js&"
export * from "./SchoolLeaveDetailPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e67505",
  null
  
)

export default component.exports