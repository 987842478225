<template>
  <div>
    <SchoolLeaveDetailTableControl class="mb-5"></SchoolLeaveDetailTableControl>

    <AppPageCard>
      <template #card-title> {{ $t("EDULEAVE.LEAVELIST") }} </template>
      <template #card-toolbar>
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <b-button
          variant="light-primary"
          class="font-weight-bolder"
          v-b-toggle.school-leave-detail-list-sidebar
        >
          {{ $t("TEACHER.BUTTON") }}
        </b-button>
      </template>

      <SchoolLeaveDetailPageTable :reload="reload"></SchoolLeaveDetailPageTable>

      <b-sidebar
        ref="school-leave-detail-list-sidebar-ref"
        id="school-leave-detail-list-sidebar"
        backdrop
        right
        lazy
        :title="$t('USERFORM.CREATE')"
        sidebar-class="offcanvas"
        header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
        bg-variant="white"
      >
        <template v-slot:header-close>
          <button
            class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          >
            <i class="ki ki-close icon-xs"></i>
          </button>
        </template>
        <div class="px-10 mt-5">
          <SchoolLeaveDetailCreateForm
            v-slot:default="{}"
            title="Leave Details"
            v-on:success="closeSideBar"
          ></SchoolLeaveDetailCreateForm>
        </div>
      </b-sidebar>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import SchoolLeaveDetailCreateForm from "@/modules/school/components/management/leave/detail/SchoolLeaveDetailCreateForm";
import SchoolLeaveDetailPageTable from "@/modules/school/components/management/leave/detail/SchoolLeaveDetailPageTable";
import SchoolLeaveDetailTableControl from "@/modules/school/components/management/leave/detail/control/SchoolLeaveDetailTableControl";
export default {
  name: "SchoolLeaveDetailPage",
  components: {
    SchoolLeaveDetailTableControl,
    SchoolLeaveDetailPageTable,
    SchoolLeaveDetailCreateForm,
    AppPageCard,
  },
  data() {
    return {
      reload: false,
    };
  },

  methods: {
    closeSideBar() {
      const sideBar = this.$refs["school-leave-detail-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
