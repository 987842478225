<template>
  <b-form class="form" v-on:submit.prevent="save">
    <b-alert show="" variant="light">
      Click <strong>Submit</strong> to confirm update the Leave Type
    </b-alert>
    <b-form-group id="type" label-cols-lg="2" :label="$t('DETAILSFORM.TYPE')">
      <b-form-input
        id="type"
        :placeholder="$t('DETAILSFORM.TYPE1')"
        v-model="$v.form.type.$model"
        :state="validateState('type')"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.type.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-alert show="" variant="light">
      Changing the entitlement
      <strong>only affects future teacher and staff</strong>. For current
      teacher and staff, please modify at the
      <strong>Leave Entitlement</strong> section.
    </b-alert>

    <!-- begin:leave unit  -->
    <b-form-group :label="$t('DETAILSFORM.UNIT')" label-cols-lg="2">
      <b-form-select
        v-model="$v.form.leaveunit.$model"
        :options="leaveunits"
        :state="validateState('leaveunit')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.leaveunit.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- begin: unit  -->
    <b-form-group label="Unit" label-cols-lg="2">
      <b-form-input
        type="number"
        v-model="$v.form.unit.$model"
        :state="validateState('unit')"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.unit.required">{{
        $t("ALERT.ALERT")
      }}</b-form-invalid-feedback>
    </b-form-group>
    <!-- end: unit  -->

    <b-form-group id="remark" label-cols-lg="2" :label="$t('JOB.REMARK')">
      <b-form-textarea
        id="remark"
        v-model="$v.form.remark.$model"
        :state="validateState('remark')"
      ></b-form-textarea>

      <b-form-invalid-feedback v-if="!$v.form.remark.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label-cols-lg="2" :label="$t('DETAILSFORM.PAID')">
      <b-form-checkbox
        inline
        size="lg"
        class="mt-md-2"
        v-model="$v.form.paid.$model"
        value="true"
        unchecked-value="false"
        >{{ $t("BUTTON.YES") }}
      </b-form-checkbox>
    </b-form-group>

    <div class="d-flex justify-content-end border-top pt-3">
      <b-button
        ref="employee-general-button"
        variant="primary"
        type="submit"
        class="font-size-lg font-weight-bold"
        v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  maxLength,
  minLength,
  numeric,
  required,
} from "vuelidate/lib/validators";

export default {
  name: "SchoolLeaveDetailEditForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Leave Type Form",
    },
    formSet: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  mounted() {
    console.log("Form data set", this.formSet);
  },
  data() {
    return {
      form: {
        type: this.formSet.name,
        remark: this.formSet.description,
        entitlement: "",
        leaveunit: this.formSet.unit_type.id,
        paid: this.formSet.is_paid_leave ? "true" : "false",
        unit: this.formSet.unit,
      },
      leaveunits: [
        { value: null, text: "Please choose the leave unit" },
        { value: "1", text: "Day" },
        { value: "2", text: "Hour" },
      ],
      daycounts: [
        { value: "1", text: "Workday" },
        { value: "2", text: "Calendar Day" },
      ],
    };
  },
  validations: {
    form: {
      type: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      remark: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(500),
      },
      unit: {
        required,
        numeric,
      },
      entitlement: {},
      leaveunit: {
        required,
      },
      paid: {},
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    /**
     *
     */
    save() {
      console.log("Emit Common Details to submit event");
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        console.log("Form in complete");
        return;
      }

      this.$emit("handle-submit", {
        name: this.form.type,
        description: this.form.remark,
        leave_unit: this.form.leaveunit,
        paid_leave: this.form.paid === "true" ?? false,
        unit: this.form.unit,
      });
    },
  },
};
</script>

<style scoped></style>
